<template>
  <div class="modal-card" style="width: auto; min-height: 80vh">
    <header class="modal-card-head">
      <p class="modal-card-title">
        All SI
      </p>
    </header>
    <section class="modal-card-body" style="background-color: white;">
      <div class="mb-5">
        <multiselect
          v-model="selectedSi"
          placeholder="Select a SI..."
          :options="siData"
          track-by="si"
          label="si"
          @input="selectSI"
        />
      </div>

      <div v-if="selectedData">
        <div class="mb-3 is-flex is-justify-content-space-between is-align-items-center">
          <div>
            <span class="mr-3">
              <b-icon
                pack="fas"
                icon="circle"
                size="is-small"
                type="is-primary"
              /> {{ selectedData.filter(b => b.locationMethod === 'sc').length }} Collected
            </span>

            <span>
              <b-icon
                pack="fas"
                icon="circle"
                size="is-small"
                type="is-info"
              /> {{ selectedData.filter(b => b.locationMethod !== 'sc').length }} In process
            </span>
          </div>
          <b-button @click="download">
            <b-icon icon="save"/>
          </b-button>
        </div>
        <b-loading v-model="isLoading" :is-full-page="false"/>
        <div>
          <!-- Segmented Bar -->
          <div class="sumup-bar">
            <div
              v-for="(value, key) in timeline"
              :key="key"
              :style="getSegmentStyle(value, key)"
              class="segment"
            >
              <span>{{ key[0].toUpperCase() + key.slice(1).toLowerCase() }}: {{ Math.round(value) }} days</span>
            </div>
          </div>
          <div class="legend">
            <span v-for="(value, key) in timeline" :key="key">
              <span class="legend-label" :style="{ color: getColor(key) }"> ▍{{ key[0].toUpperCase() + key.slice(1).toLowerCase() }}: {{ Math.round(value) }} days
              </span>
            </span>
          </div>

          <b-table
            :data="selectedData"
            paginated
            :per-page="perPage"
            mobile-cards
            hoverable
            :row-class="(row, index) => 'is-clickable'"
            @click="clickRow"
          >
            <b-table-column
              field="barcode"
              label="Barcode"
              searchable
            >
              <template
                #searchable="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Search barcode..."
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.barcode }}
              </template>
            </b-table-column>
            <b-table-column v-slot="props" field="timestamp" label="Timestamp" sortable>
              {{ new Date(props.row.timestamp).toLocaleString() }}
            </b-table-column>
            <b-table-column v-slot="props" label="Status">
              <span><b-icon
                      class="mr-1"
                      pack="fas"
                      icon="circle"
                      size="is-small"
                      :type="props.row.locationMethod === 'sc' ? 'is-primary' : 'is-info'"
                    />
                {{ getStatus(props.row) }}</span>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import IotMap from '@/services/v2/iotMap'
import { saveAs } from 'file-saver'

export default {
  props: {
    siData: {
      default: () => [],
      type: Array
    },
    customer: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      selectedSi: null,
      selectedData: null,
      siMvmts: [],
      timeline: {},
      totalDays: 0,
      perPage: 10
    }
  },
  mounted () {

  },
  methods: {
    async selectSI () {
      if (!this.selectedSi) return
      this.selectedData = this.selectedSi.barcodes
      this.isLoading = true
      try {
        this.siMvmts = await IotMap.getSiMvmts(this.selectedSi.si, this.customer)
        this.timeline = this.calAverageTime(this.siMvmts)
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
      this.isLoading = false
    },
    calAverageTime (barcodes) {
      const groupedData = barcodes.reduce((acc, b) => {
        const barcode = b.barcode
        if (!acc[barcode]) {
          acc[barcode] = []
        }

        acc[barcode].push(b)
        return acc
      }, {})

      const keyPoints = Object.keys(groupedData).map(key => {
        const barcodes = groupedData[key]
        return this.processData(barcodes)
      })

      const computedPoints = keyPoints.map(entry => {
        const result = []
        for (let i = 0; i < entry.length; i++) {
          result.push({ type: entry[i].type, duration: entry[i].duration || 0 })

          if (i !== entry.length - 1) {
            const start = entry[i].endTimestamp || entry[i].timestamp
            const end = entry[i + 1].timestamp
            const duration = this.calDuration(start, end)
            result.push({ type: 'TRANSITION', duration })
          }
        }

        return result.reduce((acc, item) => {
          const type = item.type
          if (!acc[type]) {
            acc[type] = 0
          }
          acc[type] += item.duration
          return acc
        }, {})
      })

      const average = computedPoints.reduce((acc, item) => {
        Object.keys(item).map(key => {
          if (!acc[key]) {
            acc[key] = { totalDays: 0, count: 0 }
          }
          acc[key].totalDays += item[key]
          acc[key].count += 1
        })
        return acc
      }, {})

      const result = Object.keys(average).reduce((acc, item) => {
        acc[item] = average[item].totalDays / average[item].count // Calculate average
        return acc
      }, {})

      this.totalDays = Object.values(result).reduce((sum, value) => sum + value, 0)
      return result
    },
    processData (data) {
      const nonNullData = data.filter(d => d.code)
      const filtedData = this.filterData(nonNullData)
      const keyPoints = []
      let previousTimestamp = null

      filtedData.forEach(p => {
        if (p.locationMethod === 'sd' && p.type === 'PLANT') {
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'Sent out from GPS plant',
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'PORT') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At port',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'NATURAL PRODUCER') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At natural rubber producer',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'ENDUSER') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At Enduser',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        }
      })

      return keyPoints
    },
    calDuration (start, end) {
      const startTime = this.$dayjs(start)
      return this.$dayjs(end).diff(startTime, 'day')
    },
    filterData (data) {
      const processedData = []
      let curPoint = null

      data.forEach(d => {
        if (!curPoint) {
          curPoint = { ...d }
        } else if (d.type === curPoint.type && d.code === curPoint.code) {
          curPoint.endTimestamp = d.timestamp
        } else {
          processedData.push({ ...curPoint })
          curPoint = { ...d }
        }
      })

      if (curPoint) {
        processedData.push({ ...curPoint })
      }
      return processedData
    },
    getSegmentStyle (value, key, index) {
      const percentage = (value / this.totalDays) * 100
      const colors = {
        'NATURAL PRODUCER': '#9d87de',
        PLANT: '#6abf9e',
        PORT: '#0063d2',
        ENDUSER: '#d57553'
      }

      const borderRadius = index === 0
        ? '15px 0 0 15px' // Round the left side for the first segment
        : index === Object.keys(this.timeline).length - 1
          ? '0 15px 15px 0' // Round the right side for the last segment
          : '0' // No rounding for middle segments

      return {
        width: `${percentage}%`,
        backgroundColor: colors[key] || '#ccc',
        minWidth: '2%',
        borderRadius
      }
    },
    getColor (key) {
      const colors = {
        'NATURAL PRODUCER': '#9d87de',
        PLANT: '#6abf9e',
        PORT: '#0063d2',
        ENDUSER: '#d57553'
      }
      return colors[key.toUpperCase()] || '#ccc' // Fallback to black if key not found
    },
    getStatus (data) {
      if (data.type === 'PLANT') {
        switch (data.locationMethod) {
          case 'sd':
            return 'Sent out from GPS plant'
          case 'sc':
            return 'Collected'
        }
      } else if (data.type === 'NATURAL PRODUCER') {
        return 'At producer'
      } else if (data.type === 'PORT') {
        return 'At port'
      } else if (data.type === 'ENDUSER') {
        return 'At Enduser'
      } else {
        return 'In transportation'
      }
    },
    clickRow (row) {
      this.$emit('onSelectRow', row)
    },
    async download () {
      if (!this.selectedData.length) return
      const result = this.selectedData
      const headers = Object.keys(result[0])
      const csvRows = result.map(obj =>
        headers.map(header => {
          const value = obj[header]
          if (Array.isArray(value)) {
            return `"${value.join(';')}"`
          }
          return JSON.stringify(value || '')
        }).join(',')
      )
      const final = [headers.join(','), ...csvRows]
      const blob = new Blob([final.join('\n')], { type: 'text/csv;charset=utf-8;' })
      saveAs(blob, `${this.selectedSi.si}.csv`)
    }
  }
}
</script>

<style scoped>
.is-clickable{
  cursor: pointer;
}

.sumup-bar {
  display: flex;
  height: 20px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 10px;
  border-radius: 15px; /* Round the whole bar */
  overflow: hidden; /* Ensure inner segments don't overflow */
}

.segment {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
}

.legend {
  margin-top: 10px;
  margin-bottom: 20px;
}

.legend-label {
  font-size: 14px;
  margin-right: 8px;
}

</style>
